























import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Help'
})
export default class Help extends Vue {
  active = 0
  items = [
    {
      title: 'What is the difference between a user, a delegator, and a validator?',
      // eslint-disable-next-line no-multi-str
      body: '<div><p>A user is any person who stakes any amount of CUDOS tokens.<p/>\
      <p>A validator is a pre-approved user that has a stake of at least two million CUDOS. In addition to earning staking rewards, validators receive a percentage of the rewards of their delegators as commission. Once the migration of the Cudos Network from Ethereum (PoW) to Tendermint (PoS) validators will be the ones who produce new blocks in the blockchain.</p>\
      <p>A delegator is a user who stakes any amount of CUDOS tokens through a validator. Delegators do not need any kind of approval to join the platform, nor do they need to meet any hardware requirements. These are taken care of by their validators, who in return ask for a percentage of their delegators’ staking rewards as commission.</p></div>'
    },
    {
      title: 'How can I become a validator?',
      body: '<div><p>You can start the process of becoming a validator simply by navigating to the Validator tab in the Staking Portal and filling out the form therein. Alternatively, you can contact Cudos via <a href="https://www.cudos.org/#contact-us" target="_blank">this link</a>. Once you are approved to become a validator, you must stake the minimum required amount of two million CUDOS tokens to complete the process and become an active validator in the Cudos Staking Platform.</p></div>'
    },
    {
      title: 'As a validator, can I stake more than two million CUDOS?',
      body: '<div><p>After staking the initial 2,000,000 CUDOS, you can navigate to the Validator tab in the Staking Portal and stake the extra amount you wish to stake.</p></div>'
    },
    {
      title: 'Is there a limit on the number of tokens that I can use for staking?',
      body: '<div><p>A validator cannot stake more than one billion tokens. Delegators can stake as much as they want.</p></div>'
    },
    {
      title: 'Why do I need to approve two transactions every time I stake my tokens?',
      body: '<div><p>In order for CUDOS tokens to be staked in the Cudos Staking Platform, they need to be approved by the Cudos Token smart contract. Our Staking Platform automatically submits this additional transaction whenever a user wants to stake new tokens.</p></div>'
    },
    {
      title: 'When are rewards distributed? Is there a specific time slot?',
      body: '<div><p>Rewards are distributed every block by the smart contract.</p></div>'
    },
    {
      title: 'How do I receive my rewards?',
      body: '<div><p>You must claim your rewards separately from each validator you have staked through.</p>' +
        '<ul ><li>If you are the validator, you must navigate to the Validator tab and claim your rewards.</li><li>If you are a delegator, you can claim your rewards from the Staking tab.</li></ul>' +
        '<p class="mt-4">Note that every user automatically receives their pending rewards whenever they stake or unstake tokens. Additionally, a validator automatically receives their pending rewards whenever a delegator stakes to or unstakes from them. </p></div>'
    },
    {
      title: 'How can I get my staked tokens back?',
      body: '<div><p>You must first unstake your tokens from the validators you wish to get your tokens back from. Note that you must do this separately with each of your validators. Once unstaked, those tokens stop earning rewards, and go into an unbonding state. It takes 21 days for those tokens to fully unbond, after which they will be available for withdrawal.</p>' +
        '<p>Please note that if you are a validator, your validator stake cannot drop below two million CUDOS at any time.</p></div>'
    },
    {
      title: 'As a validator, how can I get all my tokens back?',
      body: '<div><p>The only way to achieve this is by exiting the Cudos Staking Platform as a validator. Then, you may withdraw your tokens once the unbonding period of 21 days expires.</p>' +
        '<p>Please note that if you wish to become a validator again after exiting the platform, you would need to apply for it with another wallet as you cannot become a validator again with the same wallet.</p></div>'
    },
    {
      title: 'What is the unbonding period?',
      body: '<div><p>When you unstake some tokens, you need to wait for those tokens to unbond, i.e. become available for withdrawal. This period is called the unbonding period, which is 21 days long.</p></div>'
    },
    {
      title: 'When does the unbonding period start?',
      body: '<div><p>It begins the moment you unstake the tokens.</p></div>'
    },
    {
      title: 'What happens to the unbonding period if I unstake additional tokens?',
      body: '<div><p>When you unstake additional tokens from the same validator, those tokens are added to the previous ones, and the unbonding period restarts for the whole sum – including any tokens that have already unbonded but have not yet been withdrawn. On the other hand, unbonding periods of tokens from different validators are independent of each other.</p></div>'
    },
    {
      title: 'Why is my validator not active anymore?',
      body: '<div><p>If your validator is not active, this means they have exited the platform and your tokens that you have staked through them are not earning staking rewards anymore. In this case, you must navigate to the Staking tab of the Staking Portal, select that validator and collect all your tokens and pending staking rewards immediately, with no unbonding period required.</p></div>'
    },
    {
      title: 'Why did my transaction fail?',
      body: '<div><p>In most cases error messages will prevent you from sending a transaction that will fail – you can find <a href="https://github.com/CudoVentures/cudos-eth-staking-contract/blob/main/err-mapping.txt">here</a> the descriptions of the codes in those messages. However, there are instances where an Ethereum transaction might fail due to internal blockchain reasons. Similarly, Metamask might sometimes underestimate the gas amount that will be used, which will lead to a failed transaction. While this is not something Cudos can control or fix, please reach out for support in <a href="https://discord.gg/jbygBjGPWA">Discord</a> or, alternatively, resend the transaction, which should be successful in most cases.</p></div>'
    },
    {
      title: 'I have issues when using a Ledger connected with Metamask.',
      body: '<div><p>In order to use a Ledger connected to Metamask to stake, make sure that you have funded your ledger with ETH, and also that after unlocking your Ledger you have enabled smart contract data, by going to “Ethereum” -> “Settings” -> “Contract Data” and selecting “Yes”. There are, however, <a href="https://community.metamask.io/t/unable-to-connect-metamask-wallet-to-ledger/3980">reported issues</a> on the usage of Ledger with certain versions of some web browsers. Metamask support should be able to help with those.</p></div>'
    },
    {
      title: 'I still need assistance?',
      body: '<div><p>Please reach out to a team member on Discord if you require additional assistance</p><p>Discord link - <a href="https://discord.gg/jbygBjGPWA">https://discord.gg/jbygBjGPWA</a></p></div>'
    }
  ]
}
